
export const useGoogleMaps = () => {
  const config = useRuntimeConfig()

  const autocomplete = async (text) => {
    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(`/api/autocomplete?text=${text}`, {
        baseURL: config.public.nuxtAPIUrl,
      })
    }

    return await useFetch(`/api/autocomplete?text=${text}`)
    
  }

  const reverseGeocode = async (latlng) => {

    if (config.public.nuxtEnvironment === 'production') {
      return await useFetch(`/api/geocode?latlng=${latlng}`, {
        baseURL: config.public.nuxtAPIUrl,
      })
    }

    return await useFetch(`/api/geocode?latlng=${latlng}`)
    
  }
  
  return {
    autocomplete,
    reverseGeocode
  }
}