<script setup>
const props = defineProps({
  searchQuery: String,
  optionalButton: Object,
  id: {
    type: String
  },
  buttonType: {
    type: String,
    default: 'btn-id-primary'
  },
  placeholderText: {
    type: String,
    default: "Find a location near..."
  },
  showSearchButton: {
    type: Boolean,
    default: true
  },
  overrideSearch: {
    type: Boolean,
    default: false
  },  
  placeholder: {
    type: String,
    default: 'Find a location near...'
  }
})
const {autocomplete, reverseGeocode} = useGoogleMaps();
const emit = defineEmits(['onAutocomplete', 'onSearch', 'onFocusout', 'onCreateAutocomplete', 'onFocus'])


const search = ref(props.searchQuery !== undefined ? props.searchQuery : '');
const predictions = ref([])
const showAutocompleteDropdown = ref(false)
const selection = ref()

const submitForm = (event) => {
  if (selection.value.length > 0) {
    // Forcing the URL to display results from the server side
    if (props.overrideSearch) {
      emit('onSearch', selection.value);
    }
    else if (process.client) {
      window.location.href = `/location-search?s=${selection.value}&p=1`;
    }
  }
}

const setSearchText = (searchText) => {
  search.value = searchText;
}

defineExpose({
  setSearchText
})

const handleAutocomplete = async (event) => {
  showAutocompleteDropdown.value = true;  
  const {data, error} = await autocomplete(event.target.value)
  
  if (data.value.statusCode === 200) {
    predictions.value.length = 0;
    const results = data.value.body.predictions;
    results.forEach((place) => {
      predictions.value.push({
        id: place.place_id,
        description: place.description
      })
    })
  }

  emit('onCreateAutocomplete', true);

}

const handlePredictionSelection = (description) => {
  search.value = description;
  selection.value = description;
  showAutocompleteDropdown.value = false;
  emit('onAutocomplete', selection.value);
}

const handleDisplaySuggestions = (event) => {
  showAutocompleteDropdown.value = event.type === 'focus';
  emit('onFocus', true);
}

const checkValue = (event) => {
  selection.value = search.value;
  if (event.target.value !== selection.value) {
    selection.value = ''
  }
  emit('onFocusout', selection.value);
}


const handleCurrentLocation = () => {
  showAutocompleteDropdown.value = true;
  if (process.client) {
    navigator.geolocation.getCurrentPosition(async (position) => {
      // Get the current location name
      const {data: result, error: responseError} = await reverseGeocode(`${position.coords.latitude}, ${position.coords.longitude}`);
      const firstResult = result.value.body.results.length > 0 ? result.value.body.results[0] : null;
      if (firstResult) {
        search.value = firstResult.formatted_address;
      }
      else {
        // Show some predefined error message
      }
      
      showAutocompleteDropdown.value = false;
      selection.value = search.value;
      emit('onAutocomplete', selection.value);
    }, (error) => {
      // Show some predefined error message
      showAutocompleteDropdown.value = false;
    })
  }
}

onMounted(() => {
  document.body.addEventListener('click', (e) => {
    let searchInput = e.target.classList.contains('form-control')
    let predictionsList = e.target.classList.contains('location-suggestions-item')
    if (!searchInput && !predictionsList && showAutocompleteDropdown.value){
      showAutocompleteDropdown.value = false;
    }
  }, true);
})

const isDisabled = computed(() => {
  return typeof(selection.value) === 'undefined' || selection.value === '';
})

</script>
<template>
  
  <form @submit.prevent="submitForm" class="location-search-form" :id="id">
    <div class="embed-submit-field">
      <div class="form-floating">
        <input 
          type="text" 
          v-model="search"
          class="form-control"
          @dragenter="submitForm" 
          placeholder="" 
          @keyup="handleAutocomplete"
          @focus="handleDisplaySuggestions"
          @focusout="checkValue"
          @aria-label="placeholderText" 
          aria-describedby="button-addon2"
        />
        <label for="floatingInput">{{ placeholderText }}</label>
        <button class="btn btn-id-primary" :class="buttonType" type="button" id="button-addon2"  @click="submitForm" v-if="showSearchButton" :disabled="isDisabled" >Go</button>
      </div>
      <div id="location-suggestions-box" class="current-location-get flat" :class="!showAutocompleteDropdown ? 'd-none' : ''" >
        <button id="current-location-button" class="location-suggestions-item w-100" @click="handleCurrentLocation" >
          <span><span class="bi-geo-alt-fill me-2" ></span>Current location</span>
        </button>
        <button class="location-suggestions-item w-100" v-for="place, index in predictions" key="place.id" @click="handlePredictionSelection(place.description)" > 
          <label class="location-suggestions-item-text" >{{ place.description }}</label> 
        </button>
      </div>
    </div>
  </form>
  <div class="wrapper-btn-optional text-center position-relative " v-if="optionalButton">
    <div class="line-wrapper">
      <span class="line my-2 mx-2">Or</span>
        <span class="mb-4">
        <a class="btn" :class="optionalButton.buttonType + ' ' + (optionalButton.buttonAnatomy !== 'regular' ? optionalButton.buttonAnatomy : '')"
          :href="optionalButton.buttonLink">
          {{ optionalButton.buttonText }}
        </a>
      </span>
    </div>
  </div>
  
</template>

<style lang="scss">
#two-section-header {

  .location-search-form, .line-wrapper {
    margin: 20px 0px;
  }
}

.location-search-form {
  position: relative;
  margin: 0px auto;
  padding: 10px 0px;
  max-width: 400px;

  @include media-breakpoint-up(sm) {
    margin: 0px auto;
    padding: 20px 0px;
  }

  &:hover, &:focus {
    #location-suggestions-box, .embed-submit-field .form-floating {
      border: solid 1px #1f1f1f;
    }
  }

  .bi-geo-alt-fill {
    color: $color-id-green;
  }

  .embed-submit-field {
    position: relative;

    .form-floating {
      display: flex;    
      background-color: #ffffff;
      border-radius: 27.5px;
      align-items: center;
      padding-left: 10px;
      padding-right: 8px;
      border: solid 1px #d9d9d9;
      z-index: 101;  

      .form-control:not(:placeholder-shown)~label::after {
        background-color: transparent;
      }

      label {
        top: 5px;
        left: 10px;
        padding: 0.5rem 0.75rem;
      }

      input {
        border-radius: 27.5px;
        border: none;
        height: calc(3rem + 2px);
        line-height: 1.25;
        &:focus {
          box-shadow: none;
        }
      }

      button {
        padding: 8px 20px;
        border-radius: 19px;        
      }
    }
  }

  #location-suggestions-box {
    position: absolute;
    // display: none;
    width: 100%;
    top: 100px;
    background: #fff;
    z-index: 100;
    border-radius: 20px;
    border: solid 1px #d9d9d9;
    -webkit-box-shadow: 0 3px 12px 0 rgba(0,0,0,0.16);
    box-shadow: 0 3px 12px 0 rgba(0,0,0,0.16);

    &:hover, &:focus {
      border: solid 1px #1f1f1f;
    }

    &.top50 {
      top: 50px;
    }
    &.drop-up {
      top: -220px;
      max-height: 200px;
      overflow-y: scroll;
      text-align: left;
    }
    &.flat {
      top: 25px; 
      border-radius: 0 0 20px 20px;
      padding-top: 30px;

      .location-suggestions-item {
        z-index: 999999999; 
      }
      
    }
    .location-suggestions-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 20px;
      height: 50px;
      cursor: pointer;
      border: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      user-select: none;
      text-align: left;
      background-color: $color-secondary;

      &:last-child {
        border-radius: 0 0 20px 20px;
      }

      &:hover {
        background: #f3f3f3;
      }
      
      &:focus-visible {
        outline: none;
        background-color: #d9d9d9;
      }

      .location-suggestions-item-text {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: keep-all;
        display: block;
      }
    }
  }
}

.wrapper-btn-optional {  
  margin: 0 auto;

  .line-wrapper {
    max-width: 400px;
    margin: 0 auto;

    .line {
      display: flex;
      flex-direction: row;
      color: white;

      &::before, &::after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid white;
        margin: auto;
      }

      &::before {
        margin-right: 10px;
      }

      &::after {
        margin-left: 10px;
      }
    }
  }

  .btn.btn-primary {      
    text-transform: capitalize;      
  }
}
</style>